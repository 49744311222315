// Press "ESC"
$(document).keyup(function (e) {
    if (e.keyCode == 27) {
        $(".header-mainnav, .bookmarks-toggle, .search-wrapper, .search-close").removeClass("search-active");
        $(".overlay").removeClass("active menu-active search-active bookmarks-active open-position-active off-canvas-active");
        $(".hamburger").removeClass("is-active");
        $("#off-canvas").removeClass("active");
        $("#off-canvas").removeClass("off-canvas-active");
        $(".filter-select-wrapper ul").removeClass("open");
        $("body").removeClass("no-scroll no-scroll-search no-scroll-bookmarks no-scroll-off-canvas");
        $("#main-nav ul li.has-subnav").removeClass("active");
        $("#main-nav ul li").removeClass("active");
        $('.dropdown-box').removeClass('dropdown-active');
        $('.active-hover').removeClass('active-c');
    }
});

$(".overlay").click(function () {
    $(".overlay").removeClass("active open-position-active off-canvas-active");
    $(".hamburger").removeClass("is-active");
    $("#off-canvas").removeClass("active");
    $("body").removeClass("no-scroll no-scroll-search no-scroll-bookmarks no-scroll-off-canvas");
});


$(".off-canvas-toggle").click(function () {
    $(".hamburger").toggleClass("is-active");
    $("#main-wrapper, .overlay").toggleClass("off-canvas-active");
    $("#off-canvas").toggleClass("active");
    $("body").toggleClass("no-scroll-off-canvas");
});

$('.acc-menu > ul > li:has(ul)').addClass("has-subnav");
$('.acc-menu > ul > li > a').click(function() {
    $(this).closest('li').toggleClass('open') ;
});

$('.slider-wrapper').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: '<button type="button" class="slick-prev"></button>',
    nextArrow: '<button type="button" class="slick-next"></button>',
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                arrow: false,
                infinite: true
            }
        },
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true

            }
        }
    ]
});

$('.slider-image-text').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: '<button type="button" class="slick-prev"></button>',
    nextArrow: '<button type="button" class="slick-next"></button>',
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
            }
        },
        {
            breakpoint: 639,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                arrow: false
            }
        }
    ]
});

$('.slider-2images').slick({
    slidesToShow: 2,
    slidesToScroll: 1,
    prevArrow: '<button type="button" class="slick-prev"></button>',
    nextArrow: '<button type="button" class="slick-next"></button>',
    autoplay: false,
    autoplaySpeed: 2000,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
            }
        },
        {
            breakpoint: 639,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                arrow: false,
            }
        }
    ]
});

$('.slider-title').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: '<button type="button" class="slick-prev"></button>',
    nextArrow: '<button type="button" class="slick-next"></button>',
    autoplay: true,
    autoplaySpeed: 2000,
    fade: false,
    asNavFor: '.slider-nav'
});
$('.slider-nav').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: '.slider-title',
    dots: false,
    centerMode: true,
    focusOnSelect: true
});

$(".accordion").click(function(){
    $(".accordion").removeClass("active");
    $(".panel").css("display","none");

    $(this).addClass("active");
    $(this).next().css("display","block");

    $('html, body').animate({
        scrollTop: $(this).offset().top
    });

    $(this).toggleClass("dropdown-arrow-white-active");
});

$('body').on("mousewheel", function() {
    if($(document).scrollTop()>=700){
        $("#backToTopButton").css("display", "block");
    } else {
        $("#backToTopButton").css("display", "none");
    }
});

$(".has-subnav .dropdown-arrow").click(function(){
    $(this).toggleClass("dropdown-arrow-active");
});

$(".dropdown-arrow.openFormList").click(function(){

    $(this).toggleClass("dropdown-arrow-active");
    $(".dropdown-list ul.dropdown").toggleClass("dropdown-active");
});

$(document).ready(function(){
    $('.standort').hover(function(){
        var $mapaddress = (this.id).replace(/map-address-/, '');
        $('#map-point-' + $mapaddress).removeClass("map-point");
        $('#map-point-' + $mapaddress).addClass("map-point-active");
    },
    function(){
        var $mapaddress = (this.id).replace(/map-address-/, '');
        $('#map-point-' + $mapaddress).removeClass("map-point-active");
        $('#map-point-' + $mapaddress).addClass("map-point");
    });
});

$('.map-point').on('click', function(){
    $(".standort").removeClass("map-address-active");
    var $mappoint = (this.id).replace(/map-point-/, '');
    $('#map-address-' + $mappoint).addClass("map-address-active");

    if($(window).width()<1024){
        $('html, body').animate({
            scrollTop: $('#map-address-' + $mappoint).offset().top-150
        });
    }
});

$("ul.dropdown li").click(function(){
    $("ul.dropdown").removeClass("dropdown-active");
    $(".dropdown-list .dropdown-arrow").text($(this).text());

    if($(this).data("panel")=="panel1"){
        $(".grid-wrapper.panel1").removeClass("hide");
        $(".grid-wrapper.panel2").addClass("hide");
    } else{
        $(".grid-wrapper.panel2").removeClass("hide");
        $(".grid-wrapper.panel1").addClass("hide");
    }
});

//Um actpage anzupassen
window.onload = function(){
    var paginationPage = parseInt($('.cdp').attr('actpage'), 10);
    $('.cdp_i').on('click', function(){
        var go = $(this).attr('href').replace('#!', '');
        if (go === '+1') {
            paginationPage++;
        } else if (go === '-1') {
            paginationPage--;
        }else{
            paginationPage = parseInt(go, 10);
        }
        $('.cdp').attr('actpage', paginationPage);
    });
};

$(".nav-right > nav > ul > li").hover(function(){
   $(this).children().first().toggleClass("blue-bottom");
});

